import {
  Accordion,
  makeStyles,
  Spinner,
  tokens,
  ProgressBar,
  AccordionItem,
  AccordionHeader
} from '@fluentui/react-components';
import {ACAPStatus, DeviceInfo, DeviceStatus, Tag} from '../../types';
import Panel from '../../components/Panel';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {fetchTags} from '../../fetcher';
import {useCallback, useEffect, useState} from 'react';
import PromiseQueue from '../../helpers/promiseQueue';
import {CameraDome28Regular} from '@fluentui/react-icons';
import AOAPanel from './AOAPanel';
import A3DPCPanel from './A3DPCPanel';
import useToasts from '../../hooks/useToasts';
import {PanelEmptyView} from '@axiscommunications/fluent-empty-view';
import A2DPCPanel from './A2DPCPanel';

const useStyles = makeStyles({
  accordionHeader: {
    background: tokens.colorNeutralBackground2
  },
  spinner: {
    justifyContent: 'center',
    paddingLeft: tokens.spacingHorizontalM
  },
  progressBarContainer: {
    minHeight: tokens.spacingVerticalXXS
  }
});

interface DataSourcePanelProps {
  readonly device?: DeviceInfo;
  readonly panelOpen: boolean;
  readonly onClose: () => void;
}

const getPanelTitle = (model?: string, serial?: string) => {
  if (model && serial) {
    return `${model} | ${serial}`;
  } else if (serial) {
    return serial;
  } else {
    return model;
  }
};

const tagRequestQueue = new PromiseQueue();

const DataSourcePanel = ({device, panelOpen, onClose}: DataSourcePanelProps) => {
  const {organization} = usePopulatedTopbarValues();
  const styles = useStyles();
  const openTelemetry = useOpenTelemetry();
  const [pendingRequests, setPendingRequests] = useState<number>(0);
  const [tags, setTags] = useState<Tag[]>();
  const [tagApiError, setTagApiError] = useState<boolean>(false);
  const {dispatchAppToast} = useToasts();

  useEffect(() => {
    setTags(undefined);
    if (!organization?.arn) {
      return;
    }
    fetchTags({organizationArn: organization.arn}, openTelemetry).then(rsp => {
      if ('error' in rsp) {
        setTagApiError(true);
        return;
      }
      setTagApiError(false);
      setTags(rsp);
    });
  }, [organization?.arn, openTelemetry, dispatchAppToast]);

  useEffect(() => {
    if (
      tagApiError &&
      panelOpen &&
      (device?.status === DeviceStatus.Reachable || device?.status === DeviceStatus.Connected)
    ) {
      dispatchAppToast({
        title: 'Unexpected Error',
        intent: 'error',
        message: 'Failed to get tags, please reload the page'
      });
    }
  }, [tagApiError, panelOpen, dispatchAppToast, device]);

  const enqueueTagRequest = useCallback((promise: () => Promise<void>) => {
    tagRequestQueue.enqueue(promise, setPendingRequests);
  }, []);

  if (!device || !organization || device.status === undefined) {
    return (
      <Panel
        size="large"
        title={device?.name || undefined}
        subtitle={getPanelTitle(device?.model, device?.serial)}
        titleIcon={<CameraDome28Regular />}
        isOpen={panelOpen}
        onClose={onClose}
      >
        <Spinner className={styles.spinner} size="large" />
      </Panel>
    );
  }

  const A3DPCompatible = device.model?.includes('P8815');
  const noDataSources = device.acapStatuses.aoa === ACAPStatus.NotInstalled && !A3DPCompatible;

  return (
    <Panel
      size="large"
      title={device.name || undefined}
      subtitle={getPanelTitle(device.model, device.serial)}
      titleIcon={<CameraDome28Regular />}
      isOpen={panelOpen}
      onClose={onClose}
      dataTestId="data-source-panel"
    >
      <div className={styles.progressBarContainer} data-testid={'progress-bar-' + pendingRequests}>
        {pendingRequests > 1 && <ProgressBar />}
      </div>
      {device.status === DeviceStatus.Unreachable ? (
        <PanelEmptyView illustration="no-connection" title="Device is not reachable">
          Make sure that the device is connected and onboarded.
        </PanelEmptyView>
      ) : device.status === DeviceStatus.Forbidden ? (
        <PanelEmptyView illustration="no-access" title="Access denied">
          Make sure that you are permitted to configure the device.
        </PanelEmptyView>
      ) : noDataSources ? (
        <PanelEmptyView illustration="data" title="No data sources">
          Make sure that the device has a compatible data source.
        </PanelEmptyView>
      ) : (
        <Accordion collapsible defaultOpenItems={['1', '2', '3']} multiple>
          {device.acapStatuses.aoa !== ACAPStatus.NotInstalled && (
            <AccordionItem value="1">
              <AccordionHeader size="large" className={styles.accordionHeader}>
                AXIS Object Analytics
              </AccordionHeader>
              <AOAPanel device={device} tags={tags || []} enqueueTagRequest={enqueueTagRequest} />
            </AccordionItem>
          )}
          {A3DPCompatible && (
            <AccordionItem value="2">
              <AccordionHeader size="large" className={styles.accordionHeader}>
                AXIS 3D People Counter
              </AccordionHeader>
              <A3DPCPanel device={device} tags={tags || []} enqueueTagRequest={enqueueTagRequest} />
            </AccordionItem>
          )}
          {device.acapStatuses.peopleCounter !== ACAPStatus.NotInstalled && (
            <AccordionItem value="3">
              <AccordionHeader size="large" className={styles.accordionHeader}>
                AXIS People Counter
              </AccordionHeader>
              <A2DPCPanel device={device} tags={tags || []} enqueueTagRequest={enqueueTagRequest} />
            </AccordionItem>
          )}
        </Accordion>
      )}
    </Panel>
  );
};

export default DataSourcePanel;

import {PropsWithChildren} from 'react';

import {makeStyles, mergeClasses, tokens} from '@fluentui/react-components';
import ResourceGroupTree from './ResourceGroupTree';

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: tokens.colorNeutralBackground2,
    display: 'flex',
    rowGap: tokens.spacingHorizontalS,
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: tokens.spacingVerticalXXXL,
    paddingRight: tokens.spacingVerticalXXXL,
    paddingTop: tokens.spacingVerticalXXL,
    position: 'relative',
    overflow: 'hidden'
  },
  border: {
    boxShadow: tokens.shadow4,
    borderTopLeftRadius: tokens.borderRadiusXLarge
  }
});

interface PageContentProps {
  showResourceGroupTree?: boolean;
}

const PageContent = ({children, showResourceGroupTree}: PropsWithChildren<PageContentProps>) => {
  const styles = useStyles();

  return (
    <>
      {showResourceGroupTree && <ResourceGroupTree />}
      <div
        className={
          showResourceGroupTree ? mergeClasses(styles.wrapper, styles.border) : styles.wrapper
        }
        data-testid="tags-tab"
      >
        {children}
      </div>
    </>
  );
};

export default PageContent;
